import { Component, HostListener } from '@angular/core';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { UtilsService } from './shared/services';
import { AmatesIcon } from './shared/models';
import { NzIconService } from 'ng-zorro-antd/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isCollapsed = false;

  constructor(
    private nzConfigService: NzConfigService,
    private iconService: NzIconService
  ) {
    this.initIcons();

    this.onChangeConfig();
  }

  onChangeConfig() {
    this.nzConfigService.set('theme', {
      primaryColor: '#121212',
      outlineColor: '#121212'
    });
  }

  initIcons() {
    AmatesIcon.forEach((icon) =>
      this.iconService.addIconLiteral(icon.name, icon.icon)
    );
  }
}
